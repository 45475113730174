<template>
    <div class="kd-service">
		<div class="el-content mb12" v-has="{action:'wxapp_set_service_edit'}">
            <a-space>
                <a-select v-model:value="admin_id" placeholder="请选择员工" style="width:200px;">
                    <a-select-option :value="0">请选择员工</a-select-option>
                    <a-select-option v-for="item in serviceUser" :key="item.user_id" :value="item.user_id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
                <a-button type="primary" @click="setServiceUser"><i class="ri-add-line"></i>添加客服</a-button>
            </a-space>
		</div>
		<div class="el-content">
            <a-table size="small" row-key="admin_id" :pagination="false" :data-source="info.list" :columns="[
                {title:'admin_id',dataIndex:'admin_id'},
                {title:'user_id',dataIndex:'admin_user.user_id'},
                {title:'昵称',dataIndex:'admin_user.name'},
                {title:'联系电话',dataIndex:'admin_user.mobile'},
                {title:'状态',dataIndex:'status',slots:{customRender:'status'}},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]" class="mt12">
                <template #user="{record}">
                    <div style="display: flex;align-items: center;">
                        <a-avatar :src="record.im_user.avatar"/>
                        <span style="margin-left: 5px;">{{record.im_user.nick_name}}</span>
                    </div>
                </template>
                <template #manager="{record}">
                    <div v-if="record.admin_user">  
                        <p>昵称：{{record.admin_user.name}}</p>
                        <p>联系电话：{{record.admin_user.mobile}}</p>
                    </div>
                </template>
                <template #status="{record}">
                    <a-tag :color="record.im_user.status == 1 ? '#00CC66':'#999'">
                        {{record.im_user.status == 1 ? '正常':'禁用'}}
                    </a-tag>
                </template>
                <template #action="{record}">
                    <a-space v-if="record.level!=0">
                        <kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_set_service_edit'}" @click="showEdit(record)"></kd-button>
                        <kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_set_service_del'}" @click="delService(record.id)"></kd-button>
                    </a-space>
                </template>
            </a-table>
		</div>

        <a-modal v-model:visible="form.show" title="编辑客服信息" @ok="saveImInfo" @cancel="form.show = false" width="600px">
            <a-form :label-col="{span:3}" :wrapper-col="{span:16}">
                <a-form-item label="昵称">
					<a-input v-model:value="form.nickname"></a-input>
				</a-form-item>
				<a-form-item label="头像">
					<kd-img-select :src="form.avatar" @change="(url)=>{ form.avatar = url }"></kd-img-select>
				</a-form-item>
				<a-form-item label="客户端用户">
                    <com-select-user :value="form.user_id" @change="e=>form.user_id = e "></com-select-user>
					<div class="f12">客服与客户端用户绑定后,在客户端即可收到消息</div>
				</a-form-item>
				<a-form-item label="状态">
				    <a-radio-group v-model:value="form.status">
						<a-radio :value="1">正常</a-radio>
						<a-radio :value="2">禁用</a-radio>
				    </a-radio-group>
				</a-form-item>
            </a-form>
        </a-modal>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import user from '@/api/user.js'
import comSelectUser from '@/components/miniapp/com-select-user.vue'
export default {
    name:"page-service",
    components:{
        comSelectUser
    },
    setup() {
        const _d = reactive({
            info:{
                list:[],
                page:1,
                limit:10,
                count:0
            },
            serviceUser:[],
            admin_id:0,
            form:{
                show:false,
                nickname:"",
                avatar:"",
                user_id:"",
                status:"",
            }
        })
        getImList(1,10)

        //获取全部的员工信息，客服需要从员工中选择
        user.getEmployeeList().then(res=>{
            _d.serviceUser = res
        })
        function getImList(page,limit){
            user.getServiceUserList(page,limit).then(res=>{
                _d.info = { limit, ...res }
            })
        }

        function setServiceUser(){
            user.addOrEditServiceUser('add',_d.admin_id).then(res=>{
                if( res ) getImList(_d.info.page,_d.info.limit)
            })
        }

        function showEdit(row){
            _d.form.id = row ?row.cus_id:"";
            _d.form.user_id = row ?row.user_id:"";
            _d.form.avatar = row ?row.im_user.avatar:"";
            _d.form.nickname = row ?row.im_user.nick_name:"";
            _d.form.status = parseInt(row ?row.im_user.status:0) ;
            _d.form.show = true
        }

        function saveImInfo(){
            user.addOrEditServiceUser('edit',_d.form).then(()=>{
                _d.form.show = false
            })
        }

        function delService(cus_id){
            user.addOrEditServiceUser("delete",cus_id).then(()=>{
                getImList(_d.info.page,_d.info.limit)
            })
        }

        return{
            ...toRefs(_d),
            getImList,
            setServiceUser,
            saveImInfo,
            showEdit,
            delService
        }
    },
}
</script>